import PropTypes from 'prop-types';

import { useTimezone } from '@hala-systems/react-timezone-provider';

/**
 * Displays an individual timezone given the context date.
 *
 * This allows us to have a single place where we can change the way all timezones are displayed.
 */
const Timezone = ({ tz, contextDate }) => {
  const { convertBrowserToTz, formatInTimezone } = useTimezone();

  const isBrowserTz = tz === 'browser' || !tz;
  const tzLabel = isBrowserTz ? 'Browser Time' : tz;

  const d = convertBrowserToTz(contextDate ?? new Date());
  const offset = formatInTimezone(d, 'XXX', isBrowserTz ? '' : tz);

  return `${tzLabel} (UTC${offset})`;
};

Timezone.propTypes = {
  /**
   * A timezone name, or "browser"
   * [IANA Timezone Names](https://en.wikipedia.org/wiki/List_of_tz_database_time_zones)
   */
  tz: PropTypes.string.isRequired,
  /**
   * Timezone offsets are dependant on the time of year.
   * This is an optional date to help with the time of year
   */
  contextDate: PropTypes.instanceOf(Date),
};

export default Timezone;
