import { useEffect } from 'react';
import ReactGA from 'react-ga';

const GA_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

// Note, this only supports Google Analytics Universal Analytics
// properties, not the newer GA4 properties.
export const initGoogleAnalytics = () => {
  if (GA_ID) ReactGA.initialize(GA_ID);
};

/**
 * Google analytics page view tracker.
 * Note: Update this component with dynamic route, in case the app has more than one routes
 */
export const PageViewEventTracker = () => {
  useEffect(() => {
    if (GA_ID) ReactGA.pageview('/');
  });
  return null;
};
