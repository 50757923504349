import React from 'react';
import PropTypes from 'prop-types';
import Slider from '@mui/base/Slider';

export default function AudioSlider({
  duration,
  position,
  onChangePosition,
  ...props
}) {
  const value = duration ? (100 * position) / duration : 0;

  const handleChange = (_, newValue) => {
    const pos = duration ? (newValue / 100) * duration : 0;
    onChangePosition({ sliderValue: newValue, audioPosition: pos });
  };

  return (
    <Slider
      {...props}
      aria-label="audio-slider"
      value={value}
      onChange={handleChange}
    />
  );
}

AudioSlider.propTypes = {
  duration: PropTypes.number,
  position: PropTypes.number,
  onChangePosition: PropTypes.func.isRequired,
};

AudioSlider.defaultProps = {
  duration: 0,
  position: 0,
};
