import React, { createContext, useContext, useState } from 'react';

import { fakeTzDateAsLocal, fakeLocalAsTzDate, formatTzDate } from './utils';

const TimezoneContext = createContext();

export const TimezoneProvider = ({ tz = null, useUTC = true, children }) => {
  const [timezone, setTimezone] = useState(tz);

  const convertTztoBrowser = (d) => fakeTzDateAsLocal(d, timezone);
  const convertBrowserToTz = (d) => fakeLocalAsTzDate(d, timezone);
  const formatInTimezone = (d, format, tz) => {
    const dString = formatTzDate(d, format, tz ?? timezone);
    return useUTC ? dString.replace('GMT', 'UTC') : dString;
  };

  const value = {
    timezone,
    setTimezone,
    convertBrowserToTz,
    convertTztoBrowser,
    formatInTimezone,
  };

  return (
    <TimezoneContext.Provider value={value}>
      {children}
    </TimezoneContext.Provider>
  );
};

export const useTimezone = () => useContext(TimezoneContext);
