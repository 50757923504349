const BASE_THEME = {
  spacing: 4,
  typography: {
    allVariants: {
      fontFamily: [
        'proximaNova',
        '"Roboto"',
        '"Helvetica"',
        'Arial',
        'sans-serif',
      ].join(','),
    },
  },
};

export default BASE_THEME;
