import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import StyledImage from './ImageWithFallbackStyles';

import placeholderImage from './placeholder.svg';

export default function ImageWithFallback({
  label,
  src,
  setIsFallbackUsed,
  ...rest
}) {
  const [notFound, setNotFound] = useState(false);
  const showFallback = notFound || !src;

  const onError = () => {
    setNotFound(true);
    setIsFallbackUsed(true);
  };

  useEffect(() => setNotFound(false), [src]);

  return (
    <StyledImage
      src={showFallback ? placeholderImage : src}
      onError={onError}
      {...rest}
      alt={showFallback ? `Broken Image of ${label}` : `${label}`}
    />
  );
}

ImageWithFallback.propTypes = {
  // Alt text
  label: PropTypes.string,
  // Image url
  src: PropTypes.string,
  // Classname, likley used for styling
  className: PropTypes.string,
  // Used if a parent of image needs to know when a fallback is used
  setIsFallbackUsed: PropTypes.func,
};

ImageWithFallback.defaultProps = {
  label: null,
  src: null,
  className: null,
  setIsFallbackUsed: () => null,
};
