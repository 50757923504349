import {
  HoneycombThemeProvider,
  SENTRY_THEME_RESPONSIVE,
} from '@hala-systems/honeycomb';
import { CollectAuthenticator } from 'auth';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import DefaultErrorFallback from './components/Collect/Error/DefaultErrorFallback';

const Authorized = React.lazy(() => import('./components/Collect/Authorized'));

const App = () => (
  <ErrorBoundary FallbackComponent={DefaultErrorFallback}>
    <HoneycombThemeProvider theme={SENTRY_THEME_RESPONSIVE}>
      <CollectAuthenticator>
        <Authorized />
      </CollectAuthenticator>
    </HoneycombThemeProvider>
  </ErrorBoundary>
);

export default App;
