import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import { StyledDialogContent, StyledDialog } from './ImageCarouselDialogStyles';
import ImageWithFallback from '../../helpers';

export default function ImageCarouselDialog({
  imgPath,
  label,
  isOpen,
  setOpen,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <StyledDialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="Expanded Image"
      aria-describedby={`Expanded image of ${label}`}
      fullScreen
    >
      <DialogTitle sx={{ p: 0 }}>
        <IconButton
          onClick={handleClose}
          color="primary"
          aria-label="close dialog"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <StyledDialogContent>
        <ImageWithFallback
          src={imgPath}
          sx={{ height: 'auto' }}
          label={label}
        />
      </StyledDialogContent>
    </StyledDialog>
  );
}

ImageCarouselDialog.propTypes = {
  // Image src
  imgPath: PropTypes.string.isRequired,
  // Alt text
  label: PropTypes.string.isRequired,
  // Modal open state
  isOpen: PropTypes.bool.isRequired,
  // Sets modal open state
  setOpen: PropTypes.func.isRequired,
};
