import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export function initErrorTracking({ dsn, env, tracesSampleRate = 1 }) {
  if (env !== 'development' && dsn)
    Sentry.init({
      dsn,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate,
    });
}

export function captureAppMessage(errorMessage) {
  if (errorMessage) {
    Sentry.captureMessage(errorMessage);
  }
}

export function captureAppException(exception) {
  if (exception) {
    Sentry.captureException(exception);
  }
}
