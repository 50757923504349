import { Authenticator, Heading } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { userHasPermission } from 'auth/permissions';
import React, { Suspense } from 'react';
import { initAmplify } from 'services/amplify';

const Unauthorized = React.lazy(() => import('components/Collect/Unauthorized'));

initAmplify();

const components = {
  Header() {
    return (
      <Heading marginBottom="2rem" level={1} textAlign="center">
        Collect
      </Heading>
    );
  },
};

export const CollectAuthenticator = ({ children }) => (
  <Authenticator loginMechanisms={['email']} hideSignUp components={components}>
    {
      ({ user }) => (
        <Suspense fallback={<p>Loading...</p>}>
          {userHasPermission(user, 'ACCESS_COLLECT') ? children : <Unauthorized />}
        </Suspense>
      )
    }
  </Authenticator>
);
