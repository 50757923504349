import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

export default function HoneycombThemeProvider({ children, theme }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

HoneycombThemeProvider.propTypes = {
  /** MUI theme */
  theme: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};
