import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, IconButton } from '@mui/material';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

import getExtraControls from './utils';

export default function PlayerControls({
  isDisabled,
  isPlaying,
  onTogglePlay,
  onSkip,
  extraControls,
}) {
  const PlayIcon = isPlaying ? PauseIcon : PlayArrowIcon;
  const playLabel = isPlaying ? 'Pause' : 'Play';
  const extraButtons = getExtraControls(extraControls);

  return (
    <Box flexGrow={1} display="flex" direction="row" width="100%">
      <Box display="flex" flexGrow={1} flexBasis={0} justifyContent="flex-end">
        {extraButtons.left && extraButtons.left.map((b) => b.button)}
      </Box>
      <Box>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <IconButton
            label="Skip Back"
            aria-label="Skip Back"
            onClick={() => onSkip(-1)}
            disabled={isDisabled}
            size="large"
            color="primary"
          >
            <SkipPreviousIcon fontSize="small" />
          </IconButton>
          <IconButton
            label={playLabel}
            aria-label={playLabel}
            onClick={onTogglePlay}
            disabled={isDisabled}
            color={isPlaying ? 'secondary' : 'primary'}
            size="large"
          >
            <PlayIcon fontSize="large" />
          </IconButton>
          <IconButton
            label="Skip Forward"
            aria-label="Skip Forward"
            onClick={() => onSkip(1)}
            disabled={isDisabled}
            size="large"
            color="primary"
          >
            <SkipNextIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Box>

      <Box display="flex" flexGrow={1} flexBasis={0}>
        {extraButtons.right && extraButtons.right.map((b) => b.button)}
      </Box>
    </Box>
  );
}

PlayerControls.propTypes = {
  /** Enables or disables the component */
  isDisabled: PropTypes.bool,

  /** Indicates if the track should be currently playing */
  isPlaying: PropTypes.bool,

  /**
   * Icon Buttons to place to the left or right of the player controls
   */
  extraControls: PropTypes.arrayOf(
    PropTypes.shape({
      Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.elementType])
        .isRequired,
      action: PropTypes.func.isRequired,
      side: PropTypes.oneOf(['left', 'right']).isRequired,
      label: PropTypes.string.isRequired,
    })
  ),

  /**
   * Action to take when play/pause button is clicked
   * Note: play state must be managed outside of this component
   */
  onTogglePlay: PropTypes.func.isRequired,

  /**
   * Action to take when the user skips forward or backward
   * backward will pass `onSkip(-1)` and forward will pass `onSkip(1)`
   */
  onSkip: PropTypes.func.isRequired,
};

PlayerControls.defaultProps = {
  isDisabled: false,
  isPlaying: false,
  extraControls: null,
};
