import { useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { captureAppException } from 'services/errorTracking';

export default function DefaultErrorFallback({ error, resetErrorBoundary }) {
  useEffect(() => {
    captureAppException(error);
  }, [error]);

  return (
    <Snackbar
      open={true}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      ClickAwayListenerProps={{ onClickAway: () => null }}
    >
      <MuiAlert
        elevation={6}
        severity="error"
        variant="filled"
        onClose={resetErrorBoundary}
      >
        An error has ocurred. Close this message to reload the app.
      </MuiAlert>
    </Snackbar>
  );
}
