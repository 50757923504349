import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { INSIGHT_COLORS } from '../colors';
import BASE_THEME from './base';

const INSIGHT_THEME = {
  ...BASE_THEME,
  palette: {
    ...INSIGHT_COLORS,
    mode: 'dark',
  },
};

const INSIGHT_THEME_CONFIG = createTheme(INSIGHT_THEME);
const INSIGHT_THEME_RESPONSIVE = responsiveFontSizes(INSIGHT_THEME_CONFIG);

export default INSIGHT_THEME_RESPONSIVE;
