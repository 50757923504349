import padStart from 'lodash/padStart';

/**
 * Converts a # of seconds to HH:MM:SS format
 *
 * @param {Number, Float} totalSeconds the number of seconds to convert
 */
const formatSeconds = (totalSeconds) => {
  const hours = Math.floor(totalSeconds / (60 * 60));
  const minutes = Math.floor((totalSeconds - hours * 60 * 60) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  const group = hours ? [hours, minutes, seconds] : [minutes, seconds];
  return group.map((v) => padStart(v, 2, '0')).join(':');
};

export default formatSeconds;
