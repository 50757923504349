/**
 * Extract the permissions array from
 * the current user's idToken
 */
export const getUserPermissions = (user) => {
  // Note: signed in user can be accessed via `Auth.user;` from `import { Auth } from 'aws-amplify';`
  const permissions = user?.signInUserSession?.idToken?.payload?.permissions;
  return permissions ? permissions.split(',') : [];
};

export const userHasPermission = (user, permission) =>
  getUserPermissions(user).includes(permission);
