import React from 'react';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import ExtraControl from './ExtraControl';

const getExtraControls = (extraControls) =>
  groupBy(
    map(extraControls, ({ side, ...control }, i) => ({
      side,
      button: <ExtraControl key={i} {...control} />,
    })),
    'side'
  );

export default getExtraControls;
