import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';

export default function ExtraControl({
  action,
  Icon,
  label,
  buttonProps,
  iconProps,
}) {
  return (
    <IconButton
      onClick={action}
      aria-label={label}
      label={label}
      {...buttonProps}
      size="large"
    >
      <Icon fontSize="small" {...iconProps} />
    </IconButton>
  );
}

ExtraControl.propTypes = {
  action: PropTypes.func.isRequired,
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.elementType]).isRequired,
  label: PropTypes.string,
  buttonProps: PropTypes.object,
  iconProps: PropTypes.object,
};

ExtraControl.defaultProps = {
  label: null,
  buttonProps: {},
  iconProps: {},
};
