import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import StyledReactWaves from './WaveFormStyles';
import StyledAudioSlider from './AudioSliderStyles';

import TimeIndicator from './TimeIndicator';

export default function WaveForm({
  src,
  isPlaying,
  onEnded,
  height,
  options,
  isCollapsed,
}) {
  const [initializing, setInitializing] = useState();
  const [position, setPosition] = useState(0); // the position in the track
  const [duration, setDuration] = useState(null); // the duration of the track
  const [wavesurferRef, setWavesurferRef] = useState();

  useEffect(() => {
    setDuration(null);
    setPosition(0);
    // this avoids the `cannot call stop without calling start first` error
    // by resetting the component
    setInitializing(true);
    const timeoutId = setTimeout(() => setInitializing(false));
    return () => clearTimeout(timeoutId);
  }, [src]);

  const onReady = ({ wavesurfer }) => {
    setDuration(wavesurfer.getDuration());
    setWavesurferRef(wavesurfer);
  };

  const handleSliderChange = ({ sliderValue, audioPosition }) => {
    if (wavesurferRef) {
      wavesurferRef.seekTo(sliderValue / 100);
    }
    setPosition(audioPosition);
  };

  const onAudioprocess = (params) => setPosition(params[0]);

  // https://wavesurfer-js.org/docs/options.html
  const defaultOptions = {
    cursorWidth: 2,
    cursorColor: '#E31B0C',
    height,
    hideScrollbar: true,
    progressColor: '#bbbbbb',
    responsive: true,
    waveColor: '#000000',
  };

  // use a placeholder during initialization
  return initializing || !src ? (
    <Box>
      <Box display="flex" sx={{ height }} aria-label="Awaiting Source" />
      <TimeIndicator />
    </Box>
  ) : (
    <>
      <StyledReactWaves
        className={isCollapsed ? 'hidden' : ''}
        audioFile={src}
        playing={isPlaying}
        onFinish={onEnded}
        onReady={onReady}
        onAudioprocess={onAudioprocess}
        volume={2}
        zoom={1}
        options={{ ...defaultOptions, ...options }}
      />

      {isCollapsed && (
        <StyledAudioSlider
          duration={duration}
          position={position}
          onChangePosition={handleSliderChange}
        />
      )}

      <TimeIndicator position={position} duration={duration} />
    </>
  );
}

WaveForm.propTypes = {
  /** The url or base64 encoded string */
  src: PropTypes.string,
  /** Indicates if the track should be currently playing */
  isPlaying: PropTypes.bool,
  /** Any action to take when a track completes */
  onEnded: PropTypes.func,
  /** The height of the waveform in pixels */
  height: PropTypes.number,
  /** Any additional options to forward to waveplayerjs */
  options: PropTypes.object,
  /** Indicates if the waveform should be displayed or not */
  isCollapsed: PropTypes.bool,
};

WaveForm.defaultProps = {
  src: '',
  isPlaying: false,
  onEnded: null,
  height: 88,
  options: {},
  isCollapsed: false,
};
