import React from 'react';
import ReactDOM from 'react-dom';

import { initErrorTracking } from 'services/errorTracking';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initGoogleAnalytics } from 'components/Collect/helpers';

initGoogleAnalytics();

initErrorTracking({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  env: process.env.NODE_ENV,
  tracesSampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE,
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
