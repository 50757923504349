import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';

import PlayerControls from './PlayerControls';
import WaveForm from './WaveForm';

export default function AudioPlayer({
  src,
  isPlaying,
  onTogglePlay,
  onSkip,
  extraControls,
  waveformOptions,
  onTrackEnd,
  isCollapsed,
}) {
  // Because WaveForm only adds the onEnded event listener on init
  // we need to use our own event handler to conditionally run `onTrackEnd`
  useEffect(() => {
    const onEnd = () => (onTrackEnd ? onTrackEnd() : onTogglePlay());
    document.addEventListener('onEnd', onEnd);
    return () => document.removeEventListener('onEnd', onEnd);
  }, [onTrackEnd, onTogglePlay]);

  const onEnded = () => document.dispatchEvent(new Event('onEnd'));

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      component="section"
    >
      <WaveForm
        src={src}
        isPlaying={isPlaying}
        onEnded={onEnded}
        options={waveformOptions}
        isCollapsed={isCollapsed}
        height={isCollapsed ? 10 : 88}
      />
      <PlayerControls
        extraControls={extraControls}
        isDisabled={!src}
        isPlaying={isPlaying}
        onTogglePlay={onTogglePlay}
        onSkip={onSkip}
      />
    </Grid>
  );
}

AudioPlayer.propTypes = {
  /** The url or base64 encoded string */
  src: PropTypes.string,

  /** Indicates if the track should be currently playing */
  isPlaying: PropTypes.bool,

  /** Indicates if the waveform should be displayed or not */
  isCollapsed: PropTypes.bool,

  /** Any additional options to forward to waveplayerjs */
  waveformOptions: PropTypes.object,

  /**
   * Icon Buttons to place to the left or right of the player controls
   */
  extraControls: PropTypes.arrayOf(
    PropTypes.shape({
      Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.elementType])
        .isRequired,
      action: PropTypes.func.isRequired,
      side: PropTypes.oneOf(['left', 'right']).isRequired,
      label: PropTypes.string.isRequired,
    })
  ),

  /**
   * Action to take when play/pause button is clicked
   * Note: play state must be managed outside of this component
   */
  onTogglePlay: PropTypes.func.isRequired,

  /**
   * Action to take when the user skips forward or backward
   * backward will pass `onSkip(-1)` and forward will pass `onSkip(1)`
   */
  onSkip: PropTypes.func.isRequired,

  /**
   * Optional action to take when a track ends.
   *
   * Common example: `() => skip(1)`
   */
  onTrackEnd: PropTypes.func,
};

AudioPlayer.defaultProps = {
  src: '',
  isPlaying: false,
  isCollapsed: false,
  waveformOptions: {},
  extraControls: null,
  onTrackEnd: null,
};
