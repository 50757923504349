import { format as fmtTz, utcToZonedTime } from 'date-fns-tz';

/**
 * When passing the date to the picker, we want to convert from
 * the selected tz to the local machine time
 *
 * @param {Date} dateTime The Date to convert to local machine time
 * @param {string} tz The IANA timezone string we're using
 */
export const fakeTzDateAsLocal = (date, timeZone) => {
  if (!date) return null;
  if (!timeZone) return date;
  const formattedToTz = getLongNaiveDate(date, timeZone);
  return new Date(formattedToTz);
};

/**
 * When we get the date from the picker, we need to convert it
 * back to the selected tz from the local
 *
 * @param {Date} date The local machine date to fake
 * @param {string} tz The IANA timezone string
 */
export const fakeLocalAsTzDate = (date, timeZone) => {
  if (!date) return null;
  if (!timeZone) return date;

  // Simply get the local datestring and swap out the timezone
  // then re-parse
  const localNaiveDate = date.toString().substring(0, 24);
  const tzZoneString = fmtTz(new Date(), 'OOO', { timeZone });
  const tzDateString = `${localNaiveDate} ${tzZoneString}`;
  return new Date(tzDateString);
};

/**
 *
 * @param {Date} d The Date object to format
 * @param {String} format the formatting string to use
 * @param {*} tz the target timezone
 * @returns
 */
export const formatTzDate = (d, format, tz) => {
  const convertTime = tz !== null;

  return fmtTz(
    convertTime ? utcToZonedTime(d, tz) : new Date(d),
    format,
    convertTime ? { timeZone: tz } : {}
  );
};

const getLongNaiveDate = (d, tz) => formatTzDate(d, 'yyyy-MM-dd HH:mm:ss', tz);
