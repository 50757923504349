const INSIGHT_COLORS = {
  primary: {
    main: '#D0F2B3',
  },
  secondary: {
    main: '#536063',
  },
};

export default INSIGHT_COLORS;
