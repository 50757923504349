import { Amplify } from 'aws-amplify';

export const IDENTITY_POOL = process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID;
export const REGION = process.env.REACT_APP_AWS_PROJECT_REGION;
const USER_POOL_ID = process.env.REACT_APP_AWS_USER_POOL_ID;
const CLIENT_ID = process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID;

const amplifyConfig = {
  Auth: {
    identityPoolId: IDENTITY_POOL,
    region: REGION,
    userPoolId: USER_POOL_ID,
    userPoolWebClientId: CLIENT_ID,
    mandatorySignIn: false,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
};

export default amplifyConfig;

export const initAmplify = () => Amplify.configure(amplifyConfig);
